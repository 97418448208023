import * as React from 'react';
import { Script } from 'gatsby';

export default function EduSignUpPage() {
    return (
        <div>
            <div data-tf-live="01JN2GAF1GF6NEX40YANM0MK25"></div>
            <Script
                id="typeform-embed"
                type="text/javascript"
                src="//embed.typeform.com/next/embed.js"
            ></Script>
        </div>
    );
}
